<template>
  <!-- <multipane> -->
  <v-container fluid class="mt-n6">
    <UserInformation :OpenUSerIfo="OpenUSerIfo" v-show="hide"></UserInformation>
    <v-row v-if="showManual">
      <v-btn
        @click="Hide()"
        block
        color="red"
        :to="type == 'Correction' ? '/received' : '/received'"
        >GO BACK TO INBOX</v-btn
      >
      <iframe
        src="https://s3.us-east-2.amazonaws.com/inquiry.hrd-s.com/img/ReplyMessage.pdf"
        title="ReplyMessageManual"
        width="100%"
        height="800px"
      ></iframe>
    </v-row>

    <v-row v-else>
      <v-col md="4" lg="3" class="hidden-sm-and-down ml-n3">
        <!-- DEKSTOP -->
        <v-card
          v-model="drawer"
          ref="drawer"
          v-if="checkAllData"
          class="cardHeight"
          elevation="2"
          width="100%"
        >
          <v-card-title class="ma-0 pa-0">
            <v-toolbar color="#df6464" dark>
              <v-toolbar-title
                >{{ language == "en" ? "Inbox" : "受信トレイ" }}
              </v-toolbar-title>
              <v-spacer></v-spacer>
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn @click="Show()" icon v-bind="attrs" v-on="on" large
                    ><v-icon>mdi-help-circle-outline</v-icon>
                  </v-btn>
                </template>
                <span>{{ language == "en" ? "How to Use?" : "使い方？" }}</span>
              </v-tooltip>
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    v-bind="attrs"
                    v-on="on"
                    @click="refresh()"
                    icon
                    color="white"
                    :to="type == 'Correction' ? '/received' : '/received'"
                  >
                    <v-icon>mdi-refresh</v-icon>
                  </v-btn>
                </template>
                <span>{{ language == "en" ? "Refresh" : "リフレッシュ" }}</span>
              </v-tooltip>
            </v-toolbar>
            <v-text-field
              v-model="search"
              dense
              filled
              solo
              clearable
              rounded
              @click:clear="search = ''"
              color="#df6464"
              :label="language == 'en' ? 'Search' : '検索'"
              prepend-inner-icon="mdi-magnify"
              class="mr-3 ml-3 mb-n5 mt-2"
            ></v-text-field>
            <!-- DATE -->
            <v-row class="mr-3 ml-3 mb-n5 mt-2">
              <v-col>
                <v-menu
                  :close-on-content-click="false"
                  transition="scale-transition"
                  :return-value-sync="date1"
                  min-width="auto"
                  offset-y
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      dense
                      outlined
                      hide-details
                      label="Date From:"
                      prepend-inner-icon="mdi-calendar"
                      v-model="date1"
                      v-bind="attrs"
                      v-on="on"
                      readonly
                      @change="filterByDate()"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="date1"
                    :show-current="false"
                    scrollable
                    no-title
                  >
                  </v-date-picker>
                </v-menu>
              </v-col>
              <v-col>
                <v-menu
                  :close-on-content-click="false"
                  transition="scale-transition"
                  :return-value-sync="date2"
                  min-width="auto"
                  offset-y
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      dense
                      outlined
                      hide-details
                      label="Date To:"
                      prepend-inner-icon="mdi-calendar"
                      v-model="date2"
                      v-bind="attrs"
                      v-on="on"
                      readonly
                      @change="filterByDate()"
                    ></v-text-field>
                  </template>
                  <!--  @change="filterByDate()" -->
                  <v-date-picker
                    v-model="date2"
                    :show-current="false"
                    scrollable
                    no-title
                  >
                  </v-date-picker>
                </v-menu>
              </v-col>
            </v-row>
            <v-row class="mr-3 ml-3 mb-n5"
              ><v-col>
                <v-btn block dense outlined color="primary" @click="searchInbox()">
                  <!-- 
                  @change="filterByDate()" -->
                  <v-icon left> mdi-magnify </v-icon>
                  search
                </v-btn>
              </v-col>
            </v-row>
            <v-row class="mr-3 ml-3 mb-n1 mt-2">
              <v-col>
                <h3 style="float: right">Total:{{ count }}</h3>
              </v-col>
            </v-row>
            <v-divider class="mb-0"></v-divider>
          </v-card-title>
          <v-card-text
            class="ma-0 pa-0 cardList"
            style="overflow: auto; max-height: 550px"
            @scroll="loadMore($event)"
          >
            <v-list row wrap three-line class="listCard" v-if="items.length != 0">
              <template v-for="(item, value) in items">
                <v-list-item
                  :key="value.ConfirmationID"
                  @click="hideMessage(item.ConfirmationID)"
                  tag="span"
                  :to="{
                    name: 'receivedReply',
                    params: { id: item.ConfirmationID },
                  }"
                >
                  <v-list-item-content>
                    <v-list-item-title :style="changeFont(item.SeenBy).fonts"
                      ><img
                        class="mr-1"
                        v-if="changeFont(item.SeenBy).imgBoolean"
                        src="../../assets/blueCircle.png"
                        alt=""
                        style="width: 13px; height: 11px"
                      />{{ item.CustomerCode }} - {{ item.Subject }}
                    </v-list-item-title>
                    <v-list-item-subtitle class="contentAlign ml-3">{{
                      item.MessageContent
                    }}</v-list-item-subtitle>
                  </v-list-item-content>
                  <v-list-item-action-text class="ml-1">
                    <span>{{ getTime(item.DateCreated) }}</span>
                    <br />
                    <span v-html="item.ClientDate"></span>
                  </v-list-item-action-text>
                </v-list-item>
                <v-divider :key="value.ConfirmationID"></v-divider>
              </template>
              <transition name="fade">
                <div class="loading" v-show="loading">
                  <span><v-icon>mdi-loading mdi-spin</v-icon></span> Loading
                </div>
              </transition>
              <!-- <v-card v-intersect="infiniteScrolling"></v-card> -->
            </v-list>

            <div v-else class="noRecords" style="width: 100%">
              <v-img src="../../assets/inbox.png" width="65" style="margin: auto">
              </v-img>
              <b style="font-size: 17px; color: silver">{{
                language == "en" ? "No Message Found" : "メッセージが見つかりません"
              }}</b>
            </div>
          </v-card-text>

          <div v-if="items.length != 0">
            <div
              v-if="items.length === 0"
              style="
                display: flex;
                justify-content: center;
                align-items: center;
                height: 55vh;
              "
            >
              <b style="font-size: 20px; color: red; margin: auto">{{
                language == "en" ? "No Message Found" : "メッセージが見つかりません"
              }}</b>
            </div>
          </div>
        </v-card>
        <!-- LOADING SKELETON -->
        <v-skeleton-loader
          v-else
          class="cardHeight"
          elevation="2"
          type="card-avatar, article,article,article,list-item-two-line, actions"
        ></v-skeleton-loader>
      </v-col>

      <!-- FULL WIDTH -->
      <v-col v-bind:class="rowInbox">
        <v-card elevation="0" v-if="checkAllData">
          <v-toolbar color="#df6464" dark width="100%" class="d-none d-md-flex">
            <v-toolbar-title>受信トレイ</v-toolbar-title>

            <v-spacer></v-spacer>
          </v-toolbar>

          <v-list v-if="items.length != 0" three-line class="listCardMobile" v-scrollbar>
            <v-text-field
              v-model="search"
              @click:clear="search = ''"
              dense
              filled
              solo
              rounded
              clearable
              color="#df6464"
              :label="language == 'en' ? 'Search' : '検索'"
              prepend-inner-icon="mdi-magnify"
              class="mr-3 ml-3 mb-n5"
            ></v-text-field>
            <template v-for="(item, value) in items">
              <v-list-item
                :key="value.ConfirmationID"
                @click="hideInbox(item.ConfirmationID)"
                tag="span"
                :to="{
                  name: 'receivedReply',
                  params: { id: item.ConfirmationID },
                }"
              >
                <v-list-item-content>
                  <v-list-item-title :style="changeFont(item.SeenBy).fonts"
                    ><img
                      class="mr-1"
                      v-if="changeFont(item.SeenBy).imgBoolean"
                      src="../../assets/blueCircle.png"
                      alt=""
                      style="width: 13px; height: 11px"
                    />{{ item.CustomerCode }} - {{ item.Subject }}
                  </v-list-item-title>
                  <v-list-item-subtitle class="contentAlign ml-3">{{
                    item.MessageContent
                  }}</v-list-item-subtitle>
                </v-list-item-content>
                <v-list-item-action-text class="ml-1">
                  <span v-html="item.ClientDate"></span>
                </v-list-item-action-text>
              </v-list-item>
              <v-divider :key="value.ConfirmationID"></v-divider>
            </template>
          </v-list>
          <div v-else class="noRecords" style="width: 100%">
            <v-img src="../../assets/inbox.png" width="65" style="margin: auto"> </v-img>
            <b style="font-size: 17px; color: silver; margin: auto">{{
              language == "en" ? "No Message Found" : "メッセージが見つかりません"
            }}</b>
          </div>
          <div v-if="items.length != 0">
            <div
              v-if="items.length === 0"
              style="
                display: flex;
                justify-content: center;
                align-items: center;
                height: 55vh;
              "
            >
              <b style="font-size: 20px; color: red; margin: auto">{{
                language == "en" ? "No Message Found" : "メッセージが見つかりません"
              }}</b>
            </div>
          </div>
        </v-card>
        <!-- LOADING SKELETON -->
        <v-skeleton-loader
          v-else
          class="cardHeight"
          elevation="2"
          type="card-avatar, article,article,article,list-item-two-line, actions"
        ></v-skeleton-loader>
      </v-col>
      <!-- <multipane-resizer></multipane-resizer> -->
      <!-- REPLY -->

      <v-col
        class="overflow-y-auto"
        md="8"
        lg="9"
        v-bind:class="rowReply"
        :style="{ flexGrow: 1 }"
      >
        <div class="text-center mt-16" v-if="!$route.params.id">
          <b style="font-size: 23px; color: silver">{{
            language == "en" ? "No Message Selected" : "メッセージが選択されていません"
          }}</b
          ><br />
          <v-img src="../../assets/empty-email.png" width="100" style="margin: auto">
          </v-img>
        </div>
        <v-btn
          color="error"
          @click="showInbox"
          to="/received"
          class="hidden-md-and-up ml-5"
          >Back</v-btn
        >

        <transition name="fade">
          <router-view></router-view>
        </transition>
      </v-col>
    </v-row>
  </v-container>
  <!-- </multipane> -->
</template>

<script>
import Swal from "sweetalert2";
import keys from "../../functions/alert";
import axios from "axios";
import store from "@/store";
import moment from "moment-timezone";
// import { Multipane, MultipaneResizer } from "vue-multipane";
// import replyTable from "../../functions/httpRequest/ExternalConnection/ReplyTable";
import confirmationTable from "../../functions/httpRequest/ExternalConnection/ConfirmationTable";
import UserInformation from "../../components/CorrectionRequestComponent/UserInformation";
import getReply from "../../functions/httpRequest/ExternalConnection/ConfirmationTable";

export default {
  // sockets: {
  //   sendReplyTotoClient: function (socket) {
  //     console.log(socket,"372")
  //     if (
  //       socket.hrdInquiryReplyId &&
  //       (socket.hrdInquiryReplyTo == store.state.userinfo.FullName ||
  //         socket.hrInquirySenderName == store.state.userinfo.FullName)
  //     ) {
  //       this.seenBy = socket.hrdInquirySeenBy;
  //     }
  //   },
  // },

  data() {
    return {
      pageNo: 1,
      pageSize: 10,
      listElm: null,
      isLoadMore: false,
      isSearch: false,
      drawer: false,
      navigation: {
        shown: false,
        width: 550,
        borderSize: 3,
      },
      loading: false,
      page: 10,
      search: "",
      socket: "",
      show: true,
      checkAllData: false,
      rowInbox: "hidden-md-and-up",
      rowReply: "hidden-sm-and-down",
      userID: "",
      name: "",
      routerPath: "",
      type: store.state.userinfo.Type,
      items: [],
      date1: moment().subtract(1, "month").format("YYYY-MM-DD"),
      date2: moment().format("YYYY-MM-DD"),
      count: "",
      store_ReplyID: store.state.ReplyID,
      showManual: false,
      OpenUSerIfo: false,
      hide: false,
    };
  },
  components: {
    UserInformation: UserInformation,
  },

  mounted() {
    // this.setBorderWidth();
    // this.setEvents();
    this.loadDataNew();

    // this.loadData();
    this.dateCount();
  },

  created() {
    // GET LOAD DATA FUNCTION
    // this.infiniteScrolling();

    // CHECK USER TYPE
    if (store.state.userinfo.EmployeeNumber) {
      this.userID = store.state.userinfo.EmployeeNumber;
      this.name =
        store.state.userinfo.FirstNameEng + " " + store.state.userinfo.LastNameEng;
    } else {
      this.name = store.state.userinfo.FullName;
      this.userID = store.state.userinfo.EmployeeCode;
    }
    // GET SOCKET IO
    // this.socket = this.$io;
    // // GET REALTIME MESSAGE IN INBOX
    // this.socket.on("sendReplyTotoClient", (data) => {
    //   console.log(data, "448");
    //   // if (
    //   //   data.hrdInquiryReplyId &&
    //   //   (data.hrdInquiryReplyTo == store.state.userinfo.FullName ||
    //   //     data.hrInquirySenderName == store.state.userinfo.FullName)
    //   // ) {
    //   //   this.seenBy = data.hrdInquirySeenBy;
    //   this.items.push({
    //     ConfirmationID: data.ConfirmationID,
    //     DateCreated: data.DateCreated,
    //     From: data.SenderName,
    //     MessageContent: data.MessageContent,
    //     SenderID: data.SenderID,
    //     Status: null,
    //     Subject: data.Subject,
    //     To: data.To,
    //   });
    //   // }
    //   // console.log(data, "455");
    // });
    if (!store.state.userinfo.Email) {
      Swal.fire({
        title: "No Sender Email and Signature",
        text:
          'Before continuing, please complete the information in "User Information" on your Correction Request Setting page.',
        imageUrl:
          "https://s3.us-east-2.amazonaws.com/inquiry.hrd-s.com/img/UserInformationPNG.png",
        imageWidth: 450,
        imageHeight: 70,
        allowOutsideClick: false,
      }).then((result) => {
        if (result.isConfirmed) {
          this.OpenUSerIfo = true;
        }
      });
    }
  },
  computed: {
    // LANGUAGE LOCALE
    language() {
      return this.$i18n.locale;
    },
    //CHECK FOR ROUTE PATH
    checkRoutes() {
      if (this.routerPath == "/received/reply") return "";
      else return "received/reply";
    },
    //*****************************//
  },
  watch: {
    // WATCH PATH CHANGE
    $route(to) {
      this.routerPath = to.path;
    },
    emailID() {
      this.items = this.items.map((res) => {
        if (this.emailReply.ConfirmationID == res.ConfirmationID) {
          return {
            Subject: this.emailReply.Subject,
            CustomerCode: res.CustomerCode,
            SenderID: this.emailReply.SenderID,
            Department: this.emailReply.Department,
            From: this.emailReply.From,
            To: this.emailReply.To,
            Cc: this.emailReply.Cc,
            ReplyPerson: this.emailReply.ReplyPerson,
            ToEmail: this.emailReply.ToEmail,
            ReceivedDate: moment().format("YYYY-MM-DD HH:mm:ss"),
            DateCreated: this.emailReply.DateCreated,
            MessageContent: this.emailReply.MessageContent,
            ConfirmationID: this.emailReply.ConfirmationID,
            SeenBy: this.emailReply.SenderID,
            ClientDate: this.emailReply.ClientDate,
          };
        } else {
          return res;
        }
      });
    },
  },
  methods: {
    loadMore(e) {
      if (
        Math.ceil(e.target.scrollTop) + e.target.clientHeight >=
        e.target.scrollHeight
      ) {
        if (this.isLoadMore) {
          this.loadDataNew();
        }
      }
    },

    Show() {
      this.showManual = true;
    },
    // to hide iframe
    Hide() {
      this.showManual = false;
    },

    // getTime: (time) => moment(time).format("hh:mm A"),
    getTime(time) {
      if (!store.state.userinfo.accType) {
        return moment(time).tz("Asia/Manila").format("hh:mm A");
      } else {
        // country = "japan";
        return moment(time).tz("Asia/Manila").add(1, "hour").format("hh:mm A");
      }
    },
    refresh() {
      this.pageNo = 0;
      this.loadDataNew();
      this.search = "";
      this.dateCount();
    },
    searchInbox() {
      // if (this.search) {
      //   this.searchControlCode();
      //   return false;
      // } else {
      //   alert("Please Input the fields");
      //   return false;
      // }
      if (this.search) {
        this.isSearch = true;
        this.pageNo = 0;
        this.loadSearchNew();
        // this.loadDataNew()

        return false;
      } else {
        this.pageNo = 0;
        this.isSearch = false;
        this.loadSearchNew();
        // alert("Please Input the fields");
        return false;
      }
    },
    filterByDate() {
      //  this.loading = true;

      axios
        .get(
          `${keys.auth.BaseUrl}/getToConfirmation/${this.page}/${store.state.userinfo.FullName}/${store.state.userinfo.Email}/${this.date1}/${this.date2}`,
          keys.auth.apiKey
        )
        .then((res) => {
          this.items = res.data.filter((element) => {
            return element;
          });
          this.count = res.data.length;
        });
    },

    //*******************************//
    //  CHANGE FONT OF SEEN MESSAGE  //
    //*******************************//
    async dateCount() {
      await axios
        .get(
          `${keys.auth.BaseUrl}/getCountTo/${store.state.userinfo.FullName}/${store.state.userinfo.Email}/${this.date1}/${this.date2}`,
          keys.auth.apiKey
        )
        .then((res) => {
          console.log(res.data);
          this.count = res.data[0].SubjectCount;
        });
    },
    changeFont(val) {
      // IF MESSAGE CLICK
      if (val) {
        const subject = val.split(",");
        const news = subject.filter((rec) => rec == this.userID);

        if (news.length > 0) {
          return {
            fonts: "margin-left: 10px",
            imgBoolean: false,
          };
        }
      }

      // IF NOT SEEN YET
      return {
        fonts: "font-weight:bold;font-size: 15px; margin-left: -1px",
        imgBoolean: true,
      };
    },

    loadSearchNew: async function () {
      try {
        if (this.isLoadMore) {
          this.pageNo++;
        } else {
          this.pageNo = 1;
        }

        let objData = {
          pageSize: this.pageSize,
          pageNo: this.pageNo,
          nameTo: store.state.userinfo.FullName,
          search: this.search,
          emailTo: store.state.userinfo.Email,
          dateFrom: this.date1,
          dateTo: this.date2,
        };
        let dataResponse = await getReply.GetReplyToSearch(objData);

        let { data } = dataResponse;

        // Load More Function
        if (this.isLoadMore && dataResponse.length != 0) {
          if (data.length > 0) {
            this.items = Object.assign(data);

            for (let x in this.items) {
              const userDate = moment(this.items[x].DateCreated).format("YYYY-MM-DD");
              const dateFormat = moment().format("YYYY-MM-DD");
              const momentDate = moment(dateFormat);
              const dateDifference = momentDate.diff(userDate, "days");
              this.items[x].MessageContent = this.items[x].MessageContent.split(
                "<br>"
              ).join("\n");
              if (dateDifference > 1)
                this.items[x].ClientDate = moment(this.items[x].DateCreated).format(
                  "YYYY-MM-DD"
                );
              else
                this.items[x].ClientDate = moment(this.items[x].DateCreated)
                  .startOf()
                  .fromNow();
            }

            this.isLoadMore = true;
          }
        }
        // Initial Load of Data
        else {
          if (data.length > 0) {
            this.items = Object.assign(data);
            if (this.items.length > 0) {
              for (let x in this.items) {
                const userDate = moment(this.items[x].DateCreated).format("YYYY-MM-DD");
                const dateFormat = moment().format("YYYY-MM-DD");
                const momentDate = moment(dateFormat);
                const dateDifference = momentDate.diff(userDate, "days");
                this.items[x].MessageContent = this.items[x].MessageContent.split(
                  "<br>"
                ).join("\n");
                if (dateDifference > 1)
                  this.items[x].ClientDate = moment(this.items[x].DateCreated).format(
                    "YYYY-MM-DD"
                  );
                else
                  this.items[x].ClientDate = moment(this.items[x].DateCreated)
                    .startOf()
                    .fromNow();
              }
              this.isLoadMore = true;
            } else {
              this.isLoadMore = false;
            }

            this.checkAllData = true;
          }
        }
      } catch (err) {
        this.pageNo = 1;
        this.isLoadMore = false;
        alert(`something went wrong, Server Error`);
      }
    },

    loadDataNew: async function () {
      try {
        if (this.isLoadMore) {
          this.pageNo++;
        } else {
          this.pageNo = 1;
        }

        let objData = {
          pageSize: this.pageSize,
          pageNo: this.pageNo,
          nameTo: store.state.userinfo.FullName,
          search: this.search,
          emailTo: store.state.userinfo.Email,
          dateFrom: this.date1,
          dateTo: this.date2,
        };
        let dataResponse = await getReply.GetReplyTo(objData);

        let { data } = dataResponse;

        // Load More Function
        if (this.isLoadMore && dataResponse.length != 0) {
          if (data.length > 0) {
            this.items.push(...data);

            for (let x in this.items) {
              const userDate = moment(this.items[x].DateCreated).format("YYYY-MM-DD");
              const dateFormat = moment().format("YYYY-MM-DD");
              const momentDate = moment(dateFormat);
              const dateDifference = momentDate.diff(userDate, "days");
              this.items[x].MessageContent = this.items[x].MessageContent.split(
                "<br>"
              ).join("\n");
              if (dateDifference > 1)
                this.items[x].ClientDate = moment(this.items[x].DateCreated).format(
                  "YYYY-MM-DD"
                );
              else
                this.items[x].ClientDate = moment(this.items[x].DateCreated)
                  .startOf()
                  .fromNow();
            }

            this.isLoadMore = true;
          }
        }
        // Initial Load of Data
        else {
          if (data.length > 0) {
            this.items = Object.assign(data);
            if (this.items.length > 0) {
              for (let x in this.items) {
                const userDate = moment(this.items[x].DateCreated).format("YYYY-MM-DD");
                const dateFormat = moment().format("YYYY-MM-DD");
                const momentDate = moment(dateFormat);
                const dateDifference = momentDate.diff(userDate, "days");
                this.items[x].MessageContent = this.items[x].MessageContent.split(
                  "<br>"
                ).join("\n");
                if (dateDifference > 1)
                  this.items[x].ClientDate = moment(this.items[x].DateCreated).format(
                    "YYYY-MM-DD"
                  );
                else
                  this.items[x].ClientDate = moment(this.items[x].DateCreated)
                    .startOf()
                    .fromNow();
              }
              this.isLoadMore = true;
            } else {
              this.isLoadMore = false;
            }

            this.checkAllData = true;
          }
        }
      } catch (err) {
        this.pageNo = 1;
        this.isLoadMore = false;
        alert(`something went wrong, Server Error`);
      }
    },

    //*****************************//

    //******************************************//
    //    UPDATE SEEN IN DATABASE FUNCTION     //
    //*****************************************//

    hideInbox(id) {
      // UPDATE IN DATABASE
      confirmationTable.GetSpecificConfirmation(id).then(async (res) => {
        let addData = null;
        let cntData = 0;
        if (res.data[0].SeenBy) {
          const data = res.data[0].SeenBy.split(",");
          for (let x in data) if (data[x] == this.userID) cntData++;
          if (cntData == 0) addData = res.data[0].SeenBy + this.userID + ",";
          else addData = res.data[0].SeenBy;
        } else addData = this.userID + ",";

        const dataToUpdate = {
          ConfirmationID: id,
          SeenBy: addData,
        };
        await confirmationTable.Update(dataToUpdate);
      });
      // INSERT ID IN VUEX
      for (let x in this.items)
        if (this.items[x].ConfirmationID == id) this.items[x].SeenBy = this.userID;
      this.ReplyID(id);
      this.rowInbox = "hidden-sm-and-down";
      this.rowReply = "hidden-md-and-up";
      this.show = false;
    },
    //*****************************//
    //      IF MOBILE SIZE        //
    //*****************************//

    showInbox() {
      this.rowInbox = "hidden-md-and-up";
      this.rowReply = "hidden-sm-and-down";
    },
    //*******************************//
    //     IF MOBILE SIZE UPDATE     //
    //*******************************//

    hideMessage(conID) {
      confirmationTable.GetSpecificConfirmation(conID).then((res) => {
        let addData = null;
        let cntData = 0;
        if (res.data[0].SeenBy) {
          const data = res.data[0].SeenBy.split(",");
          for (let x in data) if (data[x] == this.userID) cntData++;
          if (cntData == 0) addData = res.data[0].SeenBy + this.userID + ",";
          else addData = res.data[0].SeenBy;
        } else addData = this.userID + ",";

        const dataToUpdate = {
          ConfirmationID: conID,
          SeenBy: addData,
        };

        confirmationTable.Update(dataToUpdate);
      });
      for (let x in this.items)
        if (this.items[x].ConfirmationID == conID) this.items[x].SeenBy = this.userID;
      this.ReplyID(conID);
      this.show = false;
    },
  },
};
</script>

<style lang="scss">
// .listCard {
//   overflow: auto;
//   max-height: calc(79vh - 70px);
// }
.listCardMobile {
  max-height: calc(103vh - 128px);
}
.cardHeight {
  max-height: calc(90vh - 124px);
  min-height: calc(90vh - 56px);
  // overflow: auto;
}
.noRecords {
  color: grey;
  text-align: center;
  width: 100%;
  padding-top: 25vh;
}
.contentAlign {
  max-height: 70px;
  p {
    color: silver;
    display: inline-block;
    width: 200px;
    white-space: nowrap;
    overflow: hidden !important;
    text-overflow: ellipsis;
  }
}
.loading {
  text-align: center;
  position: absolute;
  color: #fff;
  z-index: 1;
  background: #df6464;
  padding: 8px 18px;
  border-radius: 5px;
  left: calc(50% - 45px);
  top: calc(50% - 18px);
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
// .multipane.foo.layout-v .multipane-resizer {
//   margin: 0; left: 0; /* reset default styling */
//   width: 15px;
//   background: blue;
// }

// .multipane.foo.layout-h .multipane-resizer {
//   margin: 0; top: 0; /* reset default styling */
//   height: 15px;
//   background: blue;
// }
.Help {
  margin-top: -15px !important;
}
</style>
