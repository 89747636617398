import axios from "axios";
import keys from "../../alert";

const httpRequest = {
  //*****************//
  //   GET ALL USER  //
  //*****************//
  Get() {
    return Promise.resolve(
      axios.get(`${keys.auth.BaseUrl}/getCorrectionUser`, keys.auth.apiKey)
    );
  },

  //***********************//
  //   GET SPECIFIC USER   //
  //***********************//
  GetSpecificUser(id) {
    return Promise.resolve(
      axios.get(
        `${keys.auth.BaseUrl}/getCorrectionUser/${id}`,
        keys.auth.apiKey
      )
    );
  },

  getUpdatedUserInfo(id){
 
    
    
    return Promise.resolve(
      axios.get(
        `http://hrd-adminweb/api/basicinfo/${id}`,
        keys.auth.masterApi
      )
    );
  },

  //***********************//
  //   INSERT REQUEST      //
  //***********************//

  Insert(data) {
    return Promise.resolve(
      axios.post(
        `${keys.auth.BaseUrl}/insertCorrectionUser`,
        { data: data },
        keys.auth.apiKey
      )
    );
  },
  //***********************//
  //   UPDATE REQUEST      //
  //***********************//
  UpdateRecipient(data) {
    return Promise.resolve(
      axios.put(
        `${keys.auth.BaseUrl}/updateCorrectionUserRecipient`,
        { data: data },
        keys.auth.apiKey
      )
    );
  },




  Update(data) {
    return Promise.resolve(
      axios.put(
        `${keys.auth.BaseUrl}/updateCorrectionUser`,
        { data: data },
        keys.auth.apiKey
      )
    );
  },
  UpdateUserTemplate(data) {
    return Promise.resolve(
      axios.put(
        `${keys.auth.BaseUrl}/updateCorrectionUserTemplate`,
        { data: data },
        keys.auth.apiKey
      )
    );
  },
  //TODO CREATE BULK UPDATE
  BulkUpdateUsers(data) {
    console.log(data)
    return Promise.resolve(
      axios.put(
        `${keys.auth.BaseUrl}/updateCorrectionBulkUsers`,
        { data: data },
        keys.auth.apiKey
      )
    );
  },
  Delete(id) {
    return Promise.resolve(
      axios.delete(
        `${keys.auth.BaseUrl}/deleteCorrectionUser/${id}`,
        keys.auth.apiKey
      )
    );
  },
};

export default httpRequest;
